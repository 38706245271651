import React, { Component } from "react";
import "../App.css";
import styled, { keyframes } from "styled-components";
import { SoundcloudPlayer } from "./SoundcloudPlayer";
import Socials from "../components/Socials";
import { InstagramEmbed } from "react-social-media-embed";

let ritesOfPassageCovers = [
	"https://res.cloudinary.com/wy/image/upload/w_1000,q_auto,f_auto,dpr_auto/v1573052063/sd/ROP_1.jpg",
	"https://res.cloudinary.com/wy/image/upload/w_2000,f_auto,dpr_auto/v1573055459/sd/ROP_2.jpg",
	"https://res.cloudinary.com/wy/image/upload/w_2000,f_auto,dpr_auto/v1573055458/sd/ROP_3.jpg",
];
class Intro extends Component {
	constructor() {
		super();
		this.state = {
			artwork: 0,
		};
	}

	componentDidMount() {
		// updates rites of passage artwork covers
		// setTimeout(() => setInterval(this.updateCoverart, 3500), 2500);
	}

	updateCoverart = () => {
		if (this.state.artwork >= 2) {
			this.setState({ artwork: 0 });
		} else this.setState({ artwork: this.state.artwork + 1 });
	};

	render() {
		const ReleaseCover = (props) => {
			return (
				<ContentContainer>
					<a href={props.href} target="_blank" rel="noopener noreferrer">
						<img alt={props.alt} src={props.src}></img>
					</a>
				</ContentContainer>
			);
		};

		return (
			<SiteContainer>
				<TitleWrapper>
					<IntroWrapper>
						<About id="about">
							<EmbedContainer padBottom>
								<iframe
									title="Y3 Presentation | Fall/Winter 2025"
									src="https://www.youtube.com/embed/Pp8dpuAQvtM"
									frameborder="0"
									allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
									allowfullscreen
								></iframe>
							</EmbedContainer>

							<ReleaseCover
								href={"https://privatepossessions.bandcamp.com/album/y-3001"}
								alt={"Solitary Dancer - Y-3001"}
								src={
									"https://res.cloudinary.com/wy/image/upload/w_1000,q_auto,f_auto,dpr_auto/v1739750083/sd/Y-3001_Vinyl_Label.jpg"
								}
							/>

							<ContentContainer padBottom>
								<InstagramEmbed url="https://www.instagram.com/reel/DBTpSkKt9wW/?utm_source=ig_embed&amp;utm_campaign=loading" />
							</ContentContainer>
							<EmbedContainer padBottom>
								<iframe
									title="Y3 Presentation | Spring / Summer 2025"
									src="https://www.youtube.com/embed/6lKQpFWAdpo"
									frameborder="0"
									allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
									allowfullscreen
								></iframe>
							</EmbedContainer>
							<EmbedContainer padBottom>
								<iframe
									title="ZOO by Will Niava"
									src="https://player.vimeo.com/video/479486845"
									frameborder="0"
									allow="autoplay; fullscreen"
									allowfullscreen
								></iframe>
							</EmbedContainer>
							<EmbedContainer>
								<iframe
									title="Solitary Dancer - U.N.I.T.Y."
									src="https://www.youtube.com/embed/aCn4ny56RNU"
									frameborder="0"
									allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
									allowfullscreen
								></iframe>
							</EmbedContainer>

							<ReleaseCover
								href={"https://privatepossessions.bandcamp.com/album/reveries-of-a-solitary-dancer"}
								alt={"Reveries Of A Solitary Dancer"}
								src={
									"https://res.cloudinary.com/wy/image/upload/w_1000,q_auto,f_auto,dpr_auto/v1601425898/sd/reveries_of_a_solitary_dancer.jpg"
								}
							/>

							<ReleaseCover
								href={"https://privatepossessions.bandcamp.com/album/postlude"}
								alt={"Solitary Dancer - Postlude"}
								src={
									"https://res.cloudinary.com/wy/image/upload/w_1000,q_auto,f_auto,dpr_auto/v1591626184/sd/sd_postlude.jpg"
								}
							/>

							<ReleaseCover
								href={"https://privatepossessions.bandcamp.com/album/rites-of-passage"}
								alt={"Solitary Dancer - Rites of Passage"}
								src={ritesOfPassageCovers[0]}
								id="rop-cover"
							/>

							<ReleaseCover
								href={"https://optimomusic.bandcamp.com/album/in-the-name-of-the-mother"}
								alt={"Solitary Dancer - In The Name Of The Mother"}
								src={
									"https://res.cloudinary.com/wy/image/upload/w_1000,q_auto,f_auto,dpr_auto/v1573052615/sd/EP_4_lr.jpg"
								}
							/>

							<ReleaseCover
								href={"https://solitarydancer.bandcamp.com/album/transmitting-to-heaven"}
								alt={"Solitary Dancer - Transmitting To Heaven"}
								src={"https://res.cloudinary.com/wy/image/upload/w_1000,q_auto,f_auto,dpr_auto/v1573052667/sd/EP_3.jpg"}
							/>

							<ReleaseCover
								href={"https://solitary-dancer.bandcamp.com/album/dualism"}
								alt={"Solitary Dancer - Dualism"}
								src={"https://res.cloudinary.com/wy/image/upload/w_1000,q_auto,f_auto,dpr_auto/v1573052797/sd/EP_2.jpg"}
							/>

							<ReleaseCover
								href={"https://solitarydancer.bandcamp.com/album/solitary-dancer"}
								alt={"Solitary Dancer - Solitary Dancer"}
								src={"https://res.cloudinary.com/wy/image/upload/w_1000,q_auto,f_auto,dpr_auto/v1573052799/sd/EP_1.jpg"}
							/>
						</About>
					</IntroWrapper>
				</TitleWrapper>
				<SoundcloudPlayer />
				<Socials />
			</SiteContainer>
		);
	}
}

export default Intro;

const FadeIn = keyframes`
    from { opacity: 0; visibility: hidden;}
    to { opacity: 1; visibility: visible;}
`;
const SiteContainer = styled.div``;
const TitleWrapper = styled.div`
	padding-top: 15px;
	margin-left: auto;
	margin-right: auto;

	@media (min-width: 768px) {
		padding-top: 25px;
	}
`;
const IntroWrapper = styled.div`
	display: inline-block;
	width: 95vw;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0 auto;

	img {
		width: 95vw;
		height: auto;
	}

	@media (min-width: 768px) {
		img {
			width: 50vw;
			max-width: 1000px;
			height: auto;
		}
	}
`;
const About = styled.div`
	a {
		color: black;
		text-decoration: none;
		&:hover {
			cursor: pointer;
		}
		margin: 0;
		padding: 0;
		height: 0;
		line-height: 0;
		font-size: 0;
	}

	video {
		width: 95vw;
		height: auto;
	}

	@media (min-width: 768px) {
		width: 50vw;
		max-width: 1000px;

		video {
			width: 50vw;
			height: auto;
			max-width: 1000px;
		}
	}
`;

const ContentContainer = styled.div`
	margin-bottom: ${(props) => (props.padBottom ? "2.5vw" : "initial")};

	img,
	iframe,
	object,
	embed {
		margin-bottom: 2.5vw;
	}

	@media (min-width: 768px) {
		margin-bottom: ${(props) => (props.padBottom ? "50px" : "initial")};

		img,
		iframe,
		object,
		embed {
			margin-bottom: 50px;
		}
	}
`;

const EmbedContainer = styled(ContentContainer)`
	position: relative;
	padding-bottom: 56.25%;
	height: 0;
	overflow: hidden;
	max-width: 100%;

	iframe,
	object,
	embed {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
	}

	@media (min-width: 768px) {
		margin-bottom: 50px;
	}
`;
